<template>
  <div>
    <h3 class="el-page-header">References</h3>
    <div id="tc-references" class="trucode">
      <div id="references-wrapper"></div>
    </div>
  </div>
</template>

<script>
import axios from '@/utilities/axios-global'
export default {
  name: 'References',
  data() {
    return {
      // searchQuery: '',
      referenceControl: undefined
    }
  },
  mounted: function () {
    this.initialize()
  },
  methods: {
    initialize() {
      window.document.title = 'References'

      const truCodeAuthKey = this.$ls.get('truCodeAuthKey')
      // Because of a Chrome issue with cached JS files, need to force a 1 ms timeout here so that the TruCode control renders
      // on page refresh
      setTimeout(() => {
        if (typeof window.TC.init === 'function') {
          window.TC.init({
            authorizationKey: 'Bearer ' + truCodeAuthKey,
            env: 'prod',
            printTemplate: '/static/trucode/css/print.html',
            authorizationExpired: () => {
              const jwtPromise = new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_API_BASE_URL + '/api/TruCode/jwt').then(response => {
                  resolve(response.data)
                })
              })
              // trucode expects Promise.promise to be a function, so we need to dummy this out here
              jwtPromise.promise = () => { }
              return jwtPromise
            },
            authorizationExpiredParser: function (data) {
              return data.truCodeAuthKey
            }
          })
        }

        this.referenceControl = window.TC.createControl({
          type: 'references',
          el: '#references-wrapper'
        })

        // work around from TruCode support to update the search box after the first search
        this.referenceControl.tcOn('references:search', e => {
          if (e.mockUser) this.referenceControl.$('.search-box').val(e.term)
        })

        this.triggerSearch(this.$route.query)
      }, 0)
    },
    triggerSearch(query) {
      if (query.code && query.articleId) {
        this.referenceControl.tcTrigger('references:search', {
          term: query.code,
          bookId: query.book,
          mockUser: true,
          articleId: query.articleId
        })
      } else if (query.code) {
        this.referenceControl.tcTrigger('references:search', {
          term: query.code,
          bookId: query.book,
          mockUser: true
        })
      } else {
        this.referenceControl.tcTrigger('references:search', {
          bookId: query.book,
          mockUser: true
        })
      }
    }
  },
  watch: {
    $route() {
      this.triggerSearch(this.$route.query)
    }
  }
}

</script>

<style scoped>
#references-wrapper {
  height: 700px;
}
</style>
