<template>
  <el-dialog id="proReviewDialog" :visible="show" :title="'Selected Code: ' + reviseToCode" width="90%" :before-close="handleClose">
    <div v-if="reviewSubset.disableDxPx">
      This review has not been started. Please start the review to edit codes via Code Books.
    </div>

    <template v-if="codeType === 'DX' && !reviewSubset.disableDxPx">
      <v-client-table id="dxGrid" class="hiaTable codeBooksCodeGrid" :data="reviewSubset.proRecordDxs" :columns="dx.columns" :options="dx.options">
        <template slot="exclude" slot-scope="props">
          <div>
            <input type="checkbox" :checked="props.row.exclude" disabled>
          </div>
        </template>
        <template slot="recommendedDx" slot-scope="props">
          {{props.row.deleteCode ? 'DEL' : props.row.recommendedDx}}
        </template>
        <template slot="recDescCalc" slot-scope=" props">
          {{props.row.deleteCode ? 'Delete Code' : props.row.recDescCalc}}
        </template>
        <template slot="revise" slot-scope="props">
          <el-button :disabled="props.row.deleteCode || disabled" :title="props.row.deleteCode ? 'Delete code recommendation, revise on main record page' : null" @click="reviseDxCode(props.index - 1, reviseToCode)" type="text" size="small">Revise To {{reviseToCode}}</el-button>
        </template>
        <template slot="level2Revise" slot-scope="props">
          <el-button :disabled="disabled" @click="reviseDxCode(props.index - 1, reviseToCode, true)" type="text" size="small">Revise To {{reviseToCode}}</el-button>
        </template>
      </v-client-table>

      <span slot="footer" class="dialog-footer">
        <el-popover :title="`Add ${reviseToCode}`" placement="top-start" width="300" trigger="click">
          <div style="margin-bottom: 20px;">
            <div style="margin-left: 10px;">
              <div>
                <p style="margin-top: 20px;">Select Code Type</p>
                <hia-el-select :disabled="disabled" v-model="codeNumber" placeholder="Code Type" itemkey="key" label="value" :dropdowns="dropdowns.codeTypes.list" filterable>
                </hia-el-select>
              </div>
              <el-button round style="margin-top: 10px;" :disabled="(!codeNumber) || disabled" type="primary" size="mini" @click="addDxCode(reviseToCode)">Add
              </el-button>
            </div>
          </div>
          <el-button-group slot="reference">
            <el-button class="hiaButton" type="text" plain round @click="handleClose">Cancel</el-button>
            <el-button :disabled="disabled" class="hiaButton" plain round v-if="!reviewSubset.disableDxPx">Add as Code Addition</el-button>
          </el-button-group>
        </el-popover>

        <!-- <el-button v-if="!reviewSubset.disableDxPx && reviewSubset.reviewLevel === 2" type="primary" @click="addDxCode(reviseToCode, true)">Add as Level 2 Code Addition
        </el-button> -->
      </span>
    </template>

    <template v-if="codeType === 'CPTAPC' && !reviewSubset.disableDxPx">
      <v-client-table id="cptGrid" class="hiaTable codeBooksCodeGrid" :data="reviewSubset.proRecordCpts" :columns="cpt.columns" :options="cpt.options">
        <template slot="exclude" slot-scope="props">
          <div>
            <input type="checkbox" :checked="props.row.exclude" disabled>
          </div>
        </template>
        <template slot="revise" slot-scope="props">
          <el-button :disabled="props.row.deleteCode || disabled" :title="props.row.deleteCode ? 'Delete code recommendation, revise on main record page' : null" @click="reviseCptCode(props.index - 1, reviseToCode)" type="text" size="small">Revise To {{reviseToCode}}</el-button>
        </template>
        <template slot="recommendedCpt" slot-scope="props">
          {{props.row.deleteCode ? 'DEL' : props.row.recommendedCpt}}
        </template>
        <template slot="recDescCalc" slot-scope=" props">
          {{props.row.deleteCode ? 'Delete Code' : props.row.recDescCalc}}
        </template>
        <template slot="level2Revise" slot-scope="props">
          <el-button :disabled="disabled" @click="reviseCptCode(props.index - 1, reviseToCode, true)" type="text" size="small">Revise To {{reviseToCode}}</el-button>
        </template>
      </v-client-table>

      <span slot="footer" class="dialog-footer">
        <el-popover :title="`Add ${reviseToCode}`" placement="top-start" width="300" trigger="click">
          <div style="margin-bottom: 20px;">
            <div style="margin-left: 10px;">
              <div>
                <p style="margin-top: 20px;">Select Code Type</p>
                <hia-el-select :disabled="disabled" v-model="codeNumber" placeholder="Code Type" itemkey="key" label="value" :dropdowns="dropdowns.codeTypes.list" filterable>
                </hia-el-select>
              </div>
              <el-button round style="margin-top: 10px;" :disabled="(!codeNumber) || disabled" type="primary" size="mini" @click="addCptCode(reviseToCode)">Add
              </el-button>
            </div>
          </div>
          <el-button-group slot="reference">
            <el-button class="hiaButton" type="text" plain round @click="handleClose">Cancel</el-button>
            <el-button :disabled="disabled" class="hiaButton" plain round v-if="!reviewSubset.disableDxPx">Add as Code Addition</el-button>
          </el-button-group>
        </el-popover>

        <!-- <el-button v-if="!reviewSubset.disableDxPx && reviewSubset.reviewLevel === 2" type="primary" @click="addCptCode(reviseToCode, true)">Add as Level 2 Code Addition
        </el-button> -->
      </span>
    </template>

  </el-dialog>
</template>

<script>
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import { mapState } from 'vuex'

  export default {
    name: 'OutpatientReviewDialog',
    mixins: [HiaAuthorization],
    props: ['reviewSubset', 'reviseToCode', 'codeType', 'disabled'],
    data() {
      return {
        show: true,
        codeNumber: null,
        dx: {
          columns: ['lineSequence', 'exclude', 'originalDx', 'originalDescCalc', 'recommendedDx', 'recDescCalc', 'level2Dx', 'level2DescCalc', 'revise', 'level2Revise'],
          options: {
            hiddenColumns: this.reviewSubset.reviewLevel !== 2 ? ['level2Dx', 'level2DescCalc', 'level2Revise'] : [],
            sortable: [],
            filterable: false,
            orderBy: {
              column: 'lineSequence',
              ascending: true
            },
            headings: {
              lineSequence: function (h) {
                return 'Line'
              },
              exclude: function (h) {
                return 'Exclude'
              },
              originalDx: function (h) {
                return 'Original Dx'
              },
              originalDescCalc: function (h) {
                return 'Original Desc'
              },
              recommendedDx: function (h) {
                return 'Recommended Dx'
              },
              recDescCalc: function (h) {
                return 'Recommended Desc'
              },
              level2Dx: function (h) {
                return 'Level 2 Dx'
              },
              level2DescCalc: function (h) {
                return 'Level 2 Desc'
              },
              revise: function (h) {
                return 'Revise'
              },
              level2Revise: function (h) {
                return 'Level 2 Revise'
              }
            },
            perPage: 50,
            sortIcon: { is: 'none' }
          }
        },
        cpt: {
          columns: ['lineSequence', 'originalCpt', 'originalDescCalc', 'recommendedCpt', 'level2Px', 'recDescCalc', 'level2Cpt', 'level2DescCalc', 'revise', 'level2Revise'],
          options: {
            hiddenColumns: this.reviewSubset.reviewLevel !== 2 ? ['level2Cpt', 'level2DescCalc', 'level2Revise'] : [],
            sortable: [],
            filterable: false,
            orderBy: {
              column: 'lineSequence',
              ascending: true
            },
            headings: {
              lineSequence: function (h) {
                return 'Line'
              },
              exclude: function (h) {
                return 'Exclude'
              },
              originalCpt: function (h) {
                return 'Original Cpt'
              },
              originalDescCalc: function (h) {
                return 'Original Desc'
              },
              recommendedCpt: function (h) {
                return 'Recommended Cpt'
              },
              recDescCalc: function (h) {
                return 'Recommended Desc'
              },
              level2Cpt: function (h) {
                return 'Level 2 Px'
              },
              level2DescCalc: function (h) {
                return 'Level 2 Desc'
              },
              revise: function (h) {
                return 'Revise'
              },
              level2Revise: function (h) {
                return 'Level 2 Revise'
              }
            },
            perPage: 50,
            sortIcon: { is: 'none' }
          }
        }
      }
    },
    mounted: function () {
      this.initialize()
    },
    methods: {
      initialize() {
        this.codeNumber = this.reviewSubset.project ? this.reviewSubset.project.defaultCodeType : null
      },
      handleClose() {
        this.$emit('close')
      },
      addDxCode(code, level2 = false) {
        const payload = {
          code: code,
          codeType: this.codeNumber,
          level2: level2
        }
        this.$emit('addDxCode', payload)
        this.$emit('close')
      },
      reviseDxCode(i, code, level2 = false) {
        const payload = {
          index: i,
          code: code,
          level2: level2
        }
        this.$emit('reviseDxCode', payload)
        this.$emit('close')
      },
      addPxCode(code, level2 = false) {
        const payload = {
          code: code,
          codeType: this.codeNumber,
          level2: level2
        }
        this.$emit('addPxCode', payload)
        this.$emit('close')
      },
      revisePxCode(i, code) {
        const payload = {
          index: i,
          code: code
        }
        this.$emit('revisePxCode', payload)
        this.$emit('close')
      },
      addCptCode(code, level2 = false) {
        const payload = {
          code: code,
          level2: level2,
          codeType: this.codeNumber
        }
        this.$emit('addCptCode', payload)
        this.$emit('close')
      },
      reviseCptCode(i, code, level2 = false) {
        const payload = {
          index: i,
          code: code,
          level2: level2
        }
        this.$emit('reviseCptCode', payload)
        this.$emit('close')
      }
    },
    computed: {
      ...mapState('dropdowns/', ['dropdowns'])
    }
  }

</script>

<style>
.codeBooksCodeGrid .VuePagination__count {
  display: none;
}
</style>
