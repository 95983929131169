<template>
  <div>
    <h3 class="el-page-header">Codebooks for {{ parent }}</h3>
    <div id="tc-codebooks" class="trucode">
      <div id="codebooks-wrapper"></div>
    </div>
    <el-dialog :visible.sync="codebookError" width="30%" :show-close="false" :close-on-click-modal="false">
      <span slot="title"> <i class="el-alert__icon el-icon-warning" style="color: #e6a23c"></i> Warning! </span>
      <p>Codebooks is missing a parent record.</p>
      <p>Please close this window and re-open from within a record.</p>
    </el-dialog>

    <InpatientReviewDialog v-if="inpatientReviewDialogVisible" :disabled="disabled" :reviewSubset="reviewSubset" :reviseToCode="reviseToCode" :codeType="codeType" @reviseDxCode="reviseInpatientReviewDxCode" @addDxCode="addInpatientReviewDxCode" @revisePxCode="reviseInpatientReviewPxCode" @addPxCode="addInpatientReviewPxCode" @reviseCptCode="reviseInpatientReviewCptCode" @addCptCode="addInpatientReviewCptCode" @close="inpatientReviewDialogVisible = false"></InpatientReviewDialog>

    <!-- <InpatientEncoderDialog v-if="inpatientEncoderDialogVisible" :reviewSubset="reviewSubset" :reviseToCode="reviseToCode" :codeType="codeType" @addDxCode="addInpatientEncoderDxCode" @addPxCode="addInpatientEncoderPxCode" @reviseDxCode="reviseInpatientEncoderDxCode" @revisePxCode="reviseInpatientEncoderPxCode" @close="inpatientEncoderDialogVisible = false">
    </InpatientEncoderDialog> -->

    <OutpatientReviewDialog v-if="outpatientReviewDialogVisible" :disabled="disabled" :reviewSubset="reviewSubset" :reviseToCode="reviseToCode" :codeType="codeType" @reviseDxCode="reviseOutpatientReviewDxCode" @addDxCode="addOutpatientReviewDxCode" @revisePxCode="reviseOutpatientReviewPxCode" @addPxCode="addOutpatientReviewPxCode" @reviseCptCode="reviseOutpatientReviewCptCode" @addCptCode="addOutpatientReviewCptCode" @close="outpatientReviewDialogVisible = false"></OutpatientReviewDialog>

    <!--
    <OutpatientEncoderDialog v-if="outpatientEncoderDialogVisible" :reviewSubset="reviewSubset" :reviseToCode="reviseToCode" :codeType="codeType" @reviseDxCode="reviseOutpatientEncoderDxCode" @addDxCode="addOutpatientEncoderDxCode" @revisePxCode="reviseOutpatientEncoderPxCode" @addPxCode="addOutpatientEncoderPxCode" @reviseCptCode="reviseOutpatientEncoderCptCode" @addCptCode="addOutpatientEncoderCptCode" @close="outpatientEncoderDialogVisible = false">
    </OutpatientEncoderDialog> -->

    <ProReviewDialog v-if="proReviewDialogVisible" :disabled="disabled" :reviewSubset="reviewSubset" :reviseToCode="reviseToCode" :codeType="codeType" @reviseDxCode="reviseProReviewDxCode" @addDxCode="addProReviewDxCode" @reviseCptCode="reviseProReviewCptCode" @addCptCode="addProReviewCptCode" @close="proReviewDialogVisible = false"> </ProReviewDialog>

    <!-- <ProEncoderDialog v-if="proEncoderDialogVisible" :reviewSubset="reviewSubset" :reviseToCode="reviseToCode" :codeType="codeType" @reviseDxCode="reviseProEncoderDxCode" @addDxCode="addProEncoderDxCode" @reviseCptCode="reviseProEncoderCptCode" @addCptCode="addProEncoderCptCode" @close="proEncoderDialogVisible = false"></ProEncoderDialog>
    -->
  </div>
</template>

<script>
  import InpatientReviewDialog from './InpatientReviewDialog'
  // import InpatientEncoderDialog from './InpatientEncoderDialog'
  import OutpatientReviewDialog from './OutpatientReviewDialog'
  // import OutpatientEncoderDialog from './OutpatientEncoderDialog'
  import ProReviewDialog from './ProReviewDialog'
  // import ProEncoderDialog from './ProEncoderDialog'

  import axios from '@/utilities/axios-global'
  import { mapActions } from 'vuex'

  export default {
    name: 'CodeBooks',
    data() {
      return {
        inpatientReviewDialogVisible: false,
        inpatientEncoderDialogVisible: false,
        outpatientReviewDialogVisible: false,
        outpatientEncoderDialogVisible: false,
        proReviewDialogVisible: false,
        proEncoderDialogVisible: false,
        reviseToCode: '',
        reviewSubset: {},
        truCodeQuery: {},
        codeBookControl: undefined,
        codeType: '',
        parent: '',
        reviewlevel: 1,
        disabled: false,
        codebookError: false
      }
    },
    mounted: function() {
      this.initialize()
    },
    methods: {
      ...mapActions('dropdowns', ['GET_DROPDOWNS']),
      async initialize() {
        if (!this.$route.query.type || !this.$route.query.id) {
          this.codebookError = true
          return
        }
        window.document.title = 'Code Books'
        const truCodeAuthKey = this.$ls.get('truCodeAuthKey')
        const storedData = this.$ls.get(`codeBookBus_${this.$route.query.type}_${this.$route.query.id}`)

        await this.GET_DROPDOWNS(['codeTypes'])

        this.parseCodeBookDataFromLocalStorage(storedData)
        this.setupLocalStorageListener()

        // Because of a Chrome issue with cached JS files, need to force
        // a timeout here so that the TruCode control renders
        // on page refresh
        setTimeout(() => {
          if (typeof window.TC.init === 'function') {
            window.TC.init({
              authorizationKey: 'Bearer ' + truCodeAuthKey,
              env: 'prod',
              authorizationExpired: () => {
                const jwtPromise = new Promise((resolve, reject) => {
                  axios.get(process.env.VUE_APP_API_BASE_URL + '/api/TruCode/jwt').then(response => {
                    resolve(response.data)
                  })
                })
                // trucode expects Promise.promise to be a function, so we need to dummy this out here
                jwtPromise.promise = () => {}
                return jwtPromise
              },
              authorizationExpiredParser: function(data) {
                return data.truCodeAuthKey
              }
            })
          }

          let bookDate = null
          if (this.reviewSubset && this.reviewSubset.dischargeDate) {
            bookDate = this.reviewSubset.dischargeDate
          }

          this.codeBookControl = window.TC.createControl({
            type: 'codeBooks',
            el: '#codebooks-wrapper',
            bookdate: bookDate,
            searchQuery: this.truCodeQuery ? this.truCodeQuery.term : '',
            book: this.truCodeQuery ? this.truCodeQuery.book : ''
          })

          this.codeBookControl.tcOn('codeBooks:codePosted', e => {
            this.reviseToCode = e.codes[0].code
            this.setCurrentCodeType(e.codes[0].codeType)

            if (this.parent === 'Inpatient Review') {
              this.inpatientReviewDialogVisible = true
            } else if (this.parent === 'Inpatient Encoder') {
              this.inpatientEncoderDialogVisible = true
            } else if (this.parent === 'Outpatient Review') {
              this.outpatientReviewDialogVisible = true
            } else if (this.parent === 'Outpatient Encoder') {
              this.outpatientEncoderDialogVisible = true
            } else if (this.parent === 'Pro Review') {
              this.proReviewDialogVisible = true
            } else if (this.parent === 'Pro Encoder') {
              this.proEncoderDialogVisible = true
            } else if (this.parent === 'Inpatient Record') {
              this.recordInpatientDialogVisible = true
            } else if (this.parent === 'Outpatient Record') {
              this.recordOutpatientDialogVisible = true
            }
          })

          this.codeBookControl.tcOn('research:referenceClick', e => {
            this.saveReferenceEventToLocalStorage(e)
          })
        }, 0)
      },
      doCodeBookSearch(truCodeQuery) {
        if (truCodeQuery && this.codeBookControl && truCodeQuery.term.length >= 3) {
          this.codeBookControl.tcTrigger('codeBooks:search', truCodeQuery)
        }
      },
      parseCodeBookDataFromLocalStorage(storedData) {
        const codeBookBus = JSON.parse(storedData)
        this.reviewSubset = codeBookBus.reviewSubset
        this.truCodeQuery = codeBookBus.truCodeQuery
        this.parent = codeBookBus.parent
        this.reviewlevel = codeBookBus.reviewlevel
        this.disabled = codeBookBus.reviewSubset.disabled

        if (this.truCodeQuery) {
          this.doCodeBookSearch(this.truCodeQuery)
        }
      },
      setupLocalStorageListener() {
        this.$ls.on(`codeBookBus_${this.$route.query.type}_${this.$route.query.id}`, storedData => {
          this.parseCodeBookDataFromLocalStorage(storedData)
        })
      },

      // Inpatient Review handlers
      reviseInpatientReviewDxCode(payload) {
        if (payload.level2) {
          this.reviewSubset.drgrecommendationsDx[payload.index].level2Dx = payload.code.replace('.', '')
        } else {
          this.reviewSubset.drgrecommendationsDx[payload.index].recommendedDx = payload.code.replace('.', '')
        }

        this.saveEventToLocalStorage('putDxCode', {
          dxCode: this.reviewSubset.drgrecommendationsDx[payload.index],
          changedField: payload.level2 ? 'level2Dx' : 'recommendedDx'
        })
      },
      reviseInpatientReviewPxCode(payload) {
        if (payload.level2) {
          this.reviewSubset.drgrecommendationsPx[payload.index].level2Px = payload.code.replace('.', '')
        } else {
          this.reviewSubset.drgrecommendationsPx[payload.index].recommendedPx = payload.code.replace('.', '')
        }

        this.saveEventToLocalStorage('putPxCode', {
          pxCode: this.reviewSubset.drgrecommendationsPx[payload.index],
          changedField: payload.level2 ? 'level2Px' : 'recommendedPx'
        })
      },
      reviseInpatientReviewCptCode(payload) {
        if (payload.level2) {
          this.reviewSubset.drgrecommendationsCPTs[payload.index].level2Cpt = payload.code.replace('.', '')
        } else {
          this.reviewSubset.drgrecommendationsCPTs[payload.index].recommendedCpt = payload.code.replace('.', '')
        }

        this.saveEventToLocalStorage('putCptCode', {
          cptCode: this.reviewSubset.drgrecommendationsCPTs[payload.index],
          changedField: payload.level2 ? 'level2Cpt' : 'recommendedCpt'
        })
      },
      addInpatientReviewDxCode(payload) {
        const codeType = payload.level2 ? 'level2Dx' : 'recommendedDx'
        const dxCode = {
          lineSequence: this.reviewSubset.drgrecommendationsDx.length + 1,
          [codeType]: payload.code.replace('.', ''),
          drgtableId: this.reviewSubset.drgtableId
        }

        this.saveEventToLocalStorage('postDxCode', {
          dxCode: [dxCode],
          changedField: 'addDxRow'
        })
      },
      addInpatientReviewPxCode(payload) {
        const codeType = payload.level2 ? 'level2Px' : 'recommendedPx'
        const pxCode = {
          lineSequence: this.reviewSubset.drgrecommendationsPx.length + 1,
          drgtableId: this.reviewSubset.drgtableId,
          [codeType]: payload.code.replace('.', '')
        }

        this.saveEventToLocalStorage('postPxCode', {
          pxCode: [pxCode],
          changedField: 'addPxRow'
        })
      },
      addInpatientReviewCptCode(payload) {
        const codeType = payload.level2 ? 'level2Cpt' : 'recommendedCpt'
        const cptCode = {
          lineSequence: this.reviewSubset.drgrecommendationsCPTs.length + 1,
          drgtableId: this.reviewSubset.drgtableId,
          [codeType]: payload.code.replace('.', '')
        }
        this.saveEventToLocalStorage('postCptCode', {
          cptCodes: [cptCode],
          changedField: 'addCptRow'
        })
      },

      // Inpatient Encoder handlers
      reviseInpatientEncoderDxCode(payload) {
        this.reviewSubset.drgrecommendationsDx[payload.index].originalDx = payload.code.replace('.', '')
        this.saveReviewToLocalStorage(this.reviewSubset)
      },
      reviseInpatientEncoderPxCode(payload) {
        this.reviewSubset.drgrecommendationsPx[payload.index].originalPx = payload.code.replace('.', '')
        this.saveReviewToLocalStorage(this.reviewSubset)
      },
      addInpatientEncoderDxCode(code) {
        const lineNo = this.reviewSubset.drgrecommendationsDx.length
          ? Math.max.apply(
              Math,
              this.reviewSubset.drgrecommendationsDx.map(function(o) {
                return o.lineNo
              })
            ) + 1
          : 1
        this.reviewSubset.drgrecommendationsDx.push({
          lineNo: lineNo,
          lineSequence: this.reviewSubset.drgrecommendationsDx.length + 1,
          originalDx: code.replace('.', '')
        })
        this.saveReviewToLocalStorage(this.reviewSubset)
      },
      addInpatientEncoderPxCode(code) {
        const lineNo = this.reviewSubset.drgrecommendationsPx.length
          ? Math.max.apply(
              Math,
              this.reviewSubset.drgrecommendationsPx.map(function(o) {
                return o.lineNo
              })
            ) + 1
          : 1
        this.reviewSubset.drgrecommendationsPx.push({
          lineNo: lineNo,
          lineSequence: this.reviewSubset.drgrecommendationsPx.length + 1,
          originalPx: code.replace('.', '')
        })
        this.saveReviewToLocalStorage(this.reviewSubset)
      },

      // Outpatient Review handlers
      reviseOutpatientReviewDxCode(payload) {
        if (payload.level2) {
          this.reviewSubset.ascRecommendationsDx[payload.index].level2Dx = payload.code.replace('.', '')
        } else {
          this.reviewSubset.ascRecommendationsDx[payload.index].recommendedDx = payload.code.replace('.', '')
        }

        this.saveEventToLocalStorage('putDxCode', {
          dxCode: this.reviewSubset.ascRecommendationsDx[payload.index],
          changedField: payload.level2 ? 'level2Dx' : 'recommendedDx'
        })
      },
      reviseOutpatientReviewPxCode(payload) {
        if (payload.level2) {
          this.reviewSubset.ascRecommendationsPx[payload.index].level2Px = payload.code.replace('.', '')
        } else {
          this.reviewSubset.ascRecommendationsPx[payload.index].recommendedPx = payload.code.replace('.', '')
        }

        this.saveEventToLocalStorage('putPxCode', {
          pxCode: this.reviewSubset.ascRecommendationsPx[payload.index],
          changedField: payload.level2 ? 'level2Px' : 'recommendedDx'
        })
      },
      reviseOutpatientReviewCptCode(payload) {
        if (payload.level2) {
          this.reviewSubset.ascRecommendationsCptsApcs[payload.index].level2Cpt = payload.code.replace('.', '')
        } else {
          this.reviewSubset.ascRecommendationsCptsApcs[payload.index].recommendedCpt = payload.code.replace('.', '')
        }

        this.saveEventToLocalStorage('putCptCode', {
          cptCode: this.reviewSubset.ascRecommendationsCptsApcs[payload.index],
          changedField: payload.level2 ? 'level2Cpt' : 'recommendedCpt'
        })
      },
      addOutpatientReviewDxCode(payload) {
        const codeType = payload.level2 ? 'level2Dx' : 'recommendedDx'
        const dxCode = {
          lineSequence: this.reviewSubset.ascRecommendationsDx.length + 1,
          [codeType]: payload.code.replace('.', ''),
          ascTableId: this.reviewSubset.ascTableId,
          codeType: payload.codeType
        }

        this.saveEventToLocalStorage('postDxCode', {
          dxCode: [dxCode],
          changedField: 'addDxRow'
        })
      },
      addOutpatientReviewPxCode(payload) {
        const codeType = payload.level2 ? 'level2Px' : 'recommendedPx'
        const pxCode = {
          lineSequence: this.reviewSubset.ascRecommendationsPx.length + 1,
          [codeType]: payload.code.replace('.', ''),
          ascTableId: this.reviewSubset.ascTableId,
          codeType: payload.codeType
        }

        this.saveEventToLocalStorage('postPxCode', {
          pxCode: [pxCode],
          changedField: 'addDxRow'
        })
      },
      addOutpatientReviewCptCode(payload) {
        const codeType = payload.level2 ? 'level2Cpt' : 'recommendedCpt'
        const cptCode = {
          lineSequence: this.reviewSubset.ascRecommendationsCptsApcs.length + 1,
          ascTableId: this.reviewSubset.ascTableId,
          [codeType]: payload.code.replace('.', ''),
          codeType: payload.codeType
        }

        this.saveEventToLocalStorage('postCptCode', {
          cptCodes: [cptCode],
          changedField: 'addCptRow'
        })
      },

      // Outpatient Encoder handlers
      reviseOutpatientEncoderDxCode(payload) {
        this.reviewSubset.ascRecommendationsDx[payload.index].originalDx = payload.code.replace('.', '')
        this.saveReviewToLocalStorage(this.reviewSubset)
      },
      reviseOutpatientEncoderPxCode(payload) {
        this.reviewSubset.ascRecommendationsPx[payload.index].originalPx = payload.code.replace('.', '')
        this.saveReviewToLocalStorage(this.reviewSubset)
      },
      reviseOutpatientEncoderCptCode(payload) {
        this.reviewSubset.ascRecommendationsCptsApcs[payload.index].originalCpt = payload.code.replace('.', '')
        this.saveReviewToLocalStorage(this.reviewSubset)
      },
      addOutpatientEncoderDxCode(code) {
        const lineNo = this.reviewSubset.ascRecommendationsDx.length
          ? Math.max.apply(
              Math,
              this.reviewSubset.ascRecommendationsDx.map(function(o) {
                return o.lineNo
              })
            ) + 1
          : 1
        this.reviewSubset.ascRecommendationsDx.push({
          lineNo: lineNo,
          lineSequence: this.reviewSubset.ascRecommendationsDx.length + 1,
          originalDx: code.replace('.', '')
        })
        this.saveReviewToLocalStorage(this.reviewSubset)
      },
      addOutpatientEncoderPxCode(code) {
        const lineNo = this.reviewSubset.ascRecommendationsPx.length
          ? Math.max.apply(
              Math,
              this.reviewSubset.ascRecommendationsPx.map(function(o) {
                return o.lineNo
              })
            ) + 1
          : 1
        this.reviewSubset.ascRecommendationsPx.push({
          lineNo: lineNo,
          lineSequence: this.reviewSubset.ascRecommendationsPx.length + 1,
          originalPx: code.replace('.', '')
        })
        this.saveReviewToLocalStorage(this.reviewSubset)
      },
      addOutpatientEncoderCptCode(code) {
        this.reviewSubset.ascRecommendationsCptsApcs.push({
          lineSequence: this.reviewSubset.ascRecommendationsCptsApcs.length + 1,
          originalCpt: code.replace('.', '')
        })
        this.saveReviewToLocalStorage(this.reviewSubset)
      },

      // Pro Review Handlers
      reviseProReviewDxCode(payload) {
        if (payload.level2) {
          this.reviewSubset.proRecordDxs[payload.index].level2Dx = payload.code.replace('.', '')
        } else {
          this.reviewSubset.proRecordDxs[payload.index].recommendedDx = payload.code.replace('.', '')
        }

        this.saveEventToLocalStorage('putDxCode', {
          dxCode: this.reviewSubset.proRecordDxs[payload.index],
          changedField: payload.level2 ? 'level2Dx' : 'recommendedDx'
        })
      },
      reviseProReviewCptCode(payload) {
        if (payload.level2) {
          this.reviewSubset.proRecordCpts[payload.index].level2Cpt = payload.code.replace('.', '')
        } else {
          this.reviewSubset.proRecordCpts[payload.index].recommendedCpt = payload.code.replace('.', '')
        }

        this.saveEventToLocalStorage('putCptCode', {
          cptCode: this.reviewSubset.proRecordCpts[payload.index],
          changedField: payload.level2 ? 'level2Cpt' : 'recommendedCpt'
        })
      },
      addProReviewDxCode(payload) {
        const field = payload.level2 ? 'level2Dx' : 'recommendedDx'
        const dxCode = {
          lineSequence: this.reviewSubset.proRecordDxs.length + 1,
          [field]: payload.code.replace('.', ''),
          proRecordId: this.reviewSubset.proRecordId,
          codeType: payload.codeType
        }

        this.saveEventToLocalStorage('postDxCode', {
          dxCode: [dxCode],
          changedField: 'addDxRow'
        })
      },
      addProReviewCptCode(payload) {
        const field = payload.level2 ? 'level2Cpt' : 'recommendedCpt'
        // needs to be an array b/c the endpoint requires an array so that multiple codes can be posted at once
        const cptCodes = [
          {
            lineSequence: this.reviewSubset.proRecordCpts.length + 1,
            [field]: payload.code.replace('.', ''),
            proRecordId: this.reviewSubset.proRecordId,
            codeType: payload.codeType
          }
        ]

        this.saveEventToLocalStorage('postCptCode', {
          cptCodes: cptCodes,
          changedField: 'addCptRow'
        })
      },
      // Pro Encoder Handlers
      reviseProEncoderDxCode(payload) {
        this.reviewSubset.proRecordDxs[payload.index].originalDx = payload.code.replace('.', '')
        this.saveReviewToLocalStorage(this.reviewSubset)
      },
      reviseProEncoderCptCode(payload) {
        this.reviewSubset.proRecordCpts[payload.index].originalCpt = payload.code.replace('.', '')
        this.saveReviewToLocalStorage(this.reviewSubset)
      },
      addProEncoderDxCode(code) {
        this.reviewSubset.proRecordDxs.push({
          lineNumber: this.reviewSubset.proRecordDxs.length
            ? Math.max.apply(
                Math,
                this.reviewSubset.proRecordDxs.map(function(o) {
                  return o.lineNumber
                })
              ) + 1
            : 1,
          lineSequence: this.reviewSubset.proRecordDxs.length + 1,
          originalDx: code.replace('.', ''),
          proRecordId: this.reviewSubset.proRecordId,
          codeType: 3
        })

        this.saveReviewToLocalStorage(this.reviewSubset)
      },
      addProEncoderCptCode(code) {
        this.reviewSubset.proRecordCpts.push({
          lineNumber: this.reviewSubset.proRecordCpts.length
            ? Math.max.apply(
                Math,
                this.reviewSubset.proRecordCpts.map(function(o) {
                  return o.lineNumber
                })
              ) + 1
            : 1,
          lineSequence: this.reviewSubset.proRecordCpts.length + 1,
          originalCpt: code.replace('.', ''),
          proRecordId: this.reviewSubset.proRecordId,
          codeType: 3
        })
        this.saveReviewToLocalStorage(this.reviewSubset)
      },
      saveEventToLocalStorage(event, payload, index = 0) {
        // this is the new style we started using for Pro
        const codeBookBus = {
          index: index,
          event: event,
          parent: this.parent,
          from: 'CodeBook',
          reviewSubset: this.reviewSubset,
          payload: payload
        }
        this.$ls.set(`codeBookBus_${this.$route.query.type}_${this.$route.query.id}`, JSON.stringify(codeBookBus))
      },
      saveReviewToLocalStorage(reviewSubset) {
        const codeBookBus = {
          reviewSubset: reviewSubset,
          parent: this.parent,
          from: 'CodeBook'
        }

        this.$ls.set(`codeBookBus_${this.$route.query.type}_${this.$route.query.id}`, JSON.stringify(codeBookBus))
      },
      saveReferenceEventToLocalStorage(referenceEvent) {
        const codeBookBus = {
          referenceEvent: referenceEvent,
          parent: this.parent,
          from: 'CodeBook'
        }

        this.$ls.set(`codeBookBus_${this.$route.query.type}_${this.$route.query.id}`, JSON.stringify(codeBookBus))
      },
      setCurrentCodeType(book) {
        const codeBookMap = {
          ICD9CM_DX: 'DX',
          ICD9CM_E: 'DX',
          ICD9CM_PR: 'PX',
          ICD10CM_DX: 'DX',
          ICD10CM_E: 'DX',
          ICD10PCS_PR: 'PX',
          CPT4: 'CPTAPC',
          HCPCS: 'CPTAPC'
        }

        this.codeType = codeBookMap[book]
      }
    },
    components: {
      InpatientReviewDialog,
      // InpatientEncoderDialog,
      OutpatientReviewDialog,
      // OutpatientEncoderDialog,
      ProReviewDialog
      // ProEncoderDialog,
      // RecordInpatientDialog,
      // RecordOutpatientDialog
    }
  }
</script>

<style scoped>
  #codebooks-wrapper {
    height: 700px;
  }

  .el-table {
    font-size: 11px;
  }

  .hidden {
    visibility: hidden;
  }

  >>> .el-alertwarning.is-light {
    background-color: #fdf6ec;
    color: #e6a23c;
  }
</style>
